@import url(~antd/dist/antd.css);
@font-face {
  font-family: 'Avenir';
  src: url("../fonts/Avenir-Roman.woff2") format("woff2"), url("../fonts/Avenir-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

#root,
.page-wrap {
  height: 100%; }

body {
  font-size: 24px;
  font-family: 'Avenir', sans-serif; }

.mt-5 {
  margin-top: 40px; }

.mt-4 {
  margin-top: 32px; }

.mt-3 {
  margin-top: 24px; }

.mt-2 {
  margin-top: 16px; }

.mt-1 {
  margin-top: 8px; }

.ml-2 {
  margin-left: 16px; }

.ml-1 {
  margin-left: 8px; }

.h-1,
.h-2,
.h-3,
.h-4,
.h-5 {
  margin-bottom: 0;
  font-family: 'Avenir', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #253149;
  font-weight: 900; }

.h-5 {
  font-size: 14px; }

.h-4 {
  font-size: 16px; }

.h-3 {
  font-size: 20px; }

.h-2 {
  font-size: 24px; }

.h-1 {
  font-size: 32px; }

.icon {
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  fill: currentColor; }

.c_green {
  color: #24c585; }

.c_blue {
  color: #2a78e4; }

.bg_palegrey {
  background-color: #f6f7fa !important; }

.w100 {
  width: 100%; }

.fz-16 {
  font-size: 16px; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.flex-center {
  display: flex;
  align-items: center; }

.flgrow {
  flex-grow: 1; }

.separator-vert {
  align-self: center;
  display: inline-block;
  width: 1px;
  height: 24px;
  margin: 0 16px;
  background-color: #d8d9e9; }

.btn,
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-shadow: none;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 4px; }
  .btn[disabled],
  .ant-btn[disabled] {
    background-color: #d0e1f9 !important;
    box-shadow: 0 2px 6px 0 rgba(61, 93, 146, 0.25) !important;
    color: #fff !important; }
  .btn .icon,
  .ant-btn .icon {
    fill: currentColor; }
  .btn .icon + span,
  .btn span + .icon,
  .ant-btn .icon + span,
  .ant-btn span + .icon {
    margin-left: 8px; }
  .btn.extrasmall,
  .ant-btn.extrasmall {
    width: 80px; }
  .btn.small,
  .ant-btn.small {
    width: 116px; }
  .btn.middle,
  .ant-btn.middle {
    width: 140px; }
  .btn.middleplus,
  .ant-btn.middleplus {
    width: 156px; }
  .btn.wide,
  .ant-btn.wide {
    width: 200px; }
  .btn.primary,
  .ant-btn.primary {
    height: 40px;
    box-shadow: 0 2px 6px 0 rgba(61, 93, 146, 0.25);
    background-color: #2a78e4;
    color: #fff;
    border: none; }
    .btn.primary:not([disabled]):hover,
    .ant-btn.primary:not([disabled]):hover {
      background-color: #13cae6; }
  .btn.secondary,
  .ant-btn.secondary {
    height: 40px;
    box-shadow: 0 2px 6px 0 rgba(61, 93, 146, 0.25);
    background-color: #ffffff;
    color: #2a78e4;
    border: none; }
    .btn.secondary:not([disabled]):hover,
    .ant-btn.secondary:not([disabled]):hover {
      box-shadow: 0 2px 6px 0 #13cae6; }
  .btn.bordered,
  .ant-btn.bordered {
    height: 40px;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rg ba(61, 93, 146, 0.25);
    background-color: #ffffff;
    border: 2px solid; }
    .btn.bordered.pending,
    .ant-btn.bordered.pending {
      color: #9d9d9d;
      border-color: #a7a7a7; }
    .btn.bordered.rejected,
    .ant-btn.bordered.rejected {
      color: #ff4c81;
      border-color: #ff4c81; }
    .btn.bordered.approved,
    .ant-btn.bordered.approved {
      color: #24c785;
      border-color: #24c785; }
    .btn.bordered.submitted,
    .ant-btn.bordered.submitted {
      color: #2a78e4;
      border-color: #2a78e4; }
  .btn.iconned,
  .ant-btn.iconned {
    height: 40px;
    color: #2a78e4;
    border: none;
    background-color: transparent !important; }
    .btn.iconned .icon,
    .ant-btn.iconned .icon {
      font-size: 20px; }
  .btn.transparent,
  .ant-btn.transparent {
    height: auto;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent; }
    .btn.transparent .icon,
    .ant-btn.transparent .icon {
      fill: currentColor; }
    .btn.transparent:not([disabled]):hover,
    .ant-btn.transparent:not([disabled]):hover {
      color: #2a78e4; }
  .btn.underlined,
  .ant-btn.underlined {
    height: auto;
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
    font-weight: 500;
    color: #2a78e4;
    text-decoration: underline; }
    .btn.underlined:not([disabled]):hover,
    .ant-btn.underlined:not([disabled]):hover {
      color: #13cae6;
      text-decoration: underline; }
    .btn.underlined[disabled],
    .ant-btn.underlined[disabled] {
      color: #92b9f1; }

a.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px; }

.link {
  font-size: 12px;
  font-weight: 500;
  color: #2a78e4;
  text-decoration: underline; }
  .link:hover {
    color: #13cae6;
    text-decoration: underline; }
  .link[disabled] {
    color: #92b9f1; }

.column-space-between {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%; }

.modal-custom .ant-modal-body {
  padding: 40px 44px 50px; }

.modal-custom .btn.underlined {
  font-size: 12px; }

.modal-custom .ant-input-affix-wrapper {
  font-size: 16px; }
  .modal-custom .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 36px;
    font-size: 14px;
    color: #253149; }

.modal-custom .close-btn {
  position: absolute;
  top: 14px;
  right: 14px;
  display: inline-flex;
  color: #2a78e4;
  font-size: 20px; }

@media only screen and (max-width: 575px) {
  .modal-custom .ant-modal-body {
    padding: 40px 24px; } }

.popover__custom {
  max-width: 302px;
  padding: 20px 16px; }
  .popover__custom p {
    margin-top: 6px;
    font-size: 12px;
    color: rgba(37, 49, 73, 0.8); }
  .popover__custom.c_green .popover__header .h-4 {
    color: #24c575; }
  .popover__custom.c_blue .popover__header .h-4 {
    color: #2a78e4; }

.popover__header {
  display: flex;
  align-items: center; }
  .popover__header .icon {
    margin-right: 16px; }

.select-label {
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(37, 49, 73, 0.5); }

.ant-select-arrow {
  color: #2a78e4;
  margin-top: -9px; }
  .ant-select-arrow .icon {
    color: #2a78e4;
    font-size: 16px;
    fill: currentColor; }

.ant-select-selection-selected-value {
  font-size: 14px; }

.notif {
  text-align: center;
  padding: 0 8px 30px; }

.content-wrap {
  background-color: #e2e6eb;
  min-height: 100%;
  padding: 40px 0 72px; }

.content-contr {
  width: 100%;
  margin: 0 auto;
  max-width: 704px;
  padding: 0 16px; }

.content__block {
  padding: 40px 48px 48px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 7px 10px 0 rgba(61, 93, 146, 0.1), 0 1px 3px 0 rgba(61, 93, 146, 0.15);
  font-size: 14px;
  color: rgba(37, 49, 73, 0.8); }
  .content__block + .content__block {
    margin-top: 32px; }

.block__header {
  text-align: center;
  margin-bottom: 24px; }

.block__header-subtitle {
  margin-top: 4px;
  font-size: 12px;
  font-style: oblique;
  color: rgba(37, 49, 73, 0.65); }

input:not([disabled]):hover,
.ant-select:not(.ant-select-disabled) .ant-select-selection:not([disabled]):hover {
  border-color: #13cae6 !important; }

input:not([disabled]):active, input:not([disabled]):focus,
.ant-select:not(.ant-select-disabled) .ant-select-selection:not([disabled]):active,
.ant-select:not(.ant-select-disabled) .ant-select-selection:not([disabled]):focus {
  border-color: #2978e3 !important;
  outline: none;
  box-shadow: none !important; }

input[disabled],
.ant-select:not(.ant-select-disabled) .ant-select-selection[disabled] {
  border-color: #d0e1f9 !important;
  background-color: #fff !important; }

.ant-select-disabled .ant-select-selection {
  border-color: #d0e1f9 !important;
  background-color: #fff !important; }
  .ant-select-disabled .ant-select-selection .ant-select-arrow .icon {
    color: #d0e1f9; }

.ant-radio-inner {
  border-color: #c4d9f7; }

.ant-radio-checked .ant-radio-inner {
  border-color: #2a78e4; }
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #1890ff; }

.ant-radio-disabled .ant-radio-inner {
  background-color: #fff !important;
  border-color: rgba(196, 217, 247, 0.51) !important; }
  .ant-radio-disabled .ant-radio-inner::after {
    background-color: rgba(196, 217, 247, 0.5); }

.ant-checkbox-inner {
  border-color: #2a78e4 !important;
  border-radius: 4px !important; }
  .ant-checkbox-inner:hover {
    border-color: #13cae6 !important; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #13cae6 !important;
  border-color: #13cae6 !important; }

.ant-checkbox-checked::after {
  border: transparent !important; }

.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d0e1f9 !important; }

.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #d0e1f9 !important; }
  .ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: #fff; }

.ant-input:placeholder-shown {
  font-size: 14px; }

.ant-form-item-label {
  padding-bottom: 4px !important;
  line-height: 1; }
  .ant-form-item-label label {
    font-size: 14px;
    font-weight: 500;
    color: rgba(37, 49, 73, 0.65);
    line-height: normal; }
    .ant-form-item-label label::after {
      content: ''; }
  .ant-form-item-label > label.ant-form-item-required::before {
    content: '*';
    font-family: 'Avenir', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 0.5;
    color: inherit; }

.ant-calendar-picker-icon {
  color: #2a78e4;
  font-size: 16px; }

.ant-form-item {
  margin-bottom: 16px; }

textarea.ant-input {
  min-height: 96px;
  resize: none; }

.ant-form-item-required::before {
  display: inline-block;
  color: inherit;
  font-size: inherit;
  font-family: 'Avenir', sans-serif;
  content: '*';
  position: absolute;
  right: -13px;
  top: 3px; }

.ant-upload.dragger {
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  border: none;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%239698BCFF' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); }

.dragger__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 32px; }

.list {
  padding-left: 0;
  margin-top: 20px; }

.list__item {
  display: flex;
  margin-top: 8px;
  font-size: 14px;
  color: rgba(37, 49, 73, 0.8); }
  .list__item > .h-5 {
    min-width: 139px;
    margin-right: 40px; }
  .list__item .list__item-val {
    width: calc(100% - 139px);
    overflow-wrap: break-word; }

.tabs-wrap {
  background-color: #e2e6eb;
  min-height: 100vh;
  padding: 0 0 54px; }
  .tabs-wrap > *:first-child {
    padding-top: 26px; }

.tabs-contr {
  width: 100%;
  margin: 0 auto;
  max-width: 1072px;
  padding: 0 16px; }

.tabs-custom .ant-tabs-bar {
  padding-top: 26px;
  background-color: #f6f7fa;
  border-color: #d8d9e9; }

.tabs-custom .ant-tabs-tabpane {
  margin: 0 auto;
  width: 100%;
  max-width: 1072px;
  padding: 16px 16px 0; }

.tabs-custom .ant-tabs-nav {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1072px;
  padding: 16px 16px 0;
  background-color: #f6f7fa; }
  .tabs-custom .ant-tabs-nav:before {
    content: '';
    position: absolute;
    left: calc((1072px - 100vw) / 2);
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: #f6f7fa; }
  .tabs-custom .ant-tabs-nav .ant-tabs-tab {
    font-size: 18px;
    font-weight: 900;
    padding: 8px 14px; }
    .tabs-custom .ant-tabs-nav .ant-tabs-tab:hover {
      color: #13cae6; }
  .tabs-custom .ant-tabs-nav .ant-tabs-ink-bar {
    position: absolute;
    bottom: 0;
    height: 4px;
    background-color: #13cae6; }
  .tabs-custom .ant-tabs-nav .ant-tabs-tab-active {
    color: #13cae6; }
    .tabs-custom .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #13cae6; }

@media only screen and (max-width: 768px) {
  .tabs-custom .ant-tabs-nav .ant-tabs-tab {
    font-size: 16px;
    margin-right: 16px; } }

.card {
  width: 100%;
  padding: 18px 32px;
  border-radius: 2px;
  box-shadow: 0 7px 10px 0 rgba(61, 93, 146, 0.1), 0 1px 3px 0 rgba(61, 93, 146, 0.15);
  background-color: #ffffff;
  color: #253149; }
  .card a {
    font-size: inherit; }
  .card + .card {
    margin-top: 24px; }
  .card p {
    margin-bottom: 0; }
  @media only screen and (max-width: 768px) {
    .card .card__header + .ant-row-flex > .ant-col {
      margin-top: 24px; }
      .card .card__header + .ant-row-flex > .ant-col:first-child {
        margin-top: 12px; }
    .card .card__col-btn {
      text-align: left; } }
  @media only screen and (max-width: 575px) {
    .card {
      width: 100%;
      padding: 32px 32px 40px; }
      .card .card__col-btn {
        margin-top: 40px !important;
        text-align: center; }
        .card .card__col-btn button {
          width: 100%;
          max-width: 241px; } }

.card__header {
  display: flex; }
  .card__header > *:first-child {
    position: relative;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(37, 49, 73, 0.1);
    margin-bottom: 6px; }

.card__col-btn {
  text-align: right; }

.pagination-custom {
  display: flex;
  justify-content: center;
  margin-top: 64px; }
  .pagination-custom .ant-pagination-item {
    min-width: 20px;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    text-align: center;
    background-color: transparent;
    border: none;
    font-family: 'Avenir', sans-serif; }
    .pagination-custom .ant-pagination-item.ant-pagination-item-active a {
      font-weight: 900;
      color: #2a78e4; }
    .pagination-custom .ant-pagination-item a {
      color: rgba(37, 49, 73, 0.3); }
  .pagination-custom .ant-pagination-prev {
    margin-right: 40px; }
    .pagination-custom .ant-pagination-prev .ant-pagination-item-link:after {
      content: 'Prev'; }
  .pagination-custom .ant-pagination-next {
    margin-left: 40px; }
    .pagination-custom .ant-pagination-next .ant-pagination-item-link:after {
      content: 'Next'; }
  .pagination-custom .ant-pagination-prev.ant-pagination-disabled .ant-pagination-item-link:after,
  .pagination-custom .ant-pagination-next.ant-pagination-disabled .ant-pagination-item-link:after {
    color: rgba(37, 49, 73, 0.3); }
  .pagination-custom .ant-pagination-prev .ant-pagination-item-link,
  .pagination-custom .ant-pagination-next .ant-pagination-item-link {
    border: none;
    background-color: transparent; }
    .pagination-custom .ant-pagination-prev .ant-pagination-item-link:after,
    .pagination-custom .ant-pagination-next .ant-pagination-item-link:after {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #2a78e4;
      text-decoration: underline; }
  .pagination-custom .ant-pagination-prev .anticon,
  .pagination-custom .ant-pagination-next .anticon {
    display: none; }

@media only screen and (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 32px);
    margin: 16px auto; } }

@media only screen and (max-width: 575px) {
  .hide-xs {
    display: none !important; }
  .childs-xs-mt-2 > * + * {
    margin-top: 16px; }
  .tabs-wrap > *:first-child {
    padding-top: 64px; } }
