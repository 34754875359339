.content-wrap {
    .searchbar {
        align-items: stretch;
        width: calc(100vw - 72px);
        margin-bottom: 36px;
    }

    .main-content {
        height: 100%;
    }
}

.category-select {
    min-width: 150px;
    margin-right: 20px;
}

.content-wrap {
    padding-top: 10px !important;
    height: 100%;
}

.faq-answer {
    font-size: 16px;
}

.faq-body {
    padding: 0px 72px 0px 72px;
}

.faq-category {
    font-size: 16px;
    text-align: right;
    font-style: italic;
}